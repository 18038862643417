.Header {
  // background: $primaryColor;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  left: 0;
  width: 100%;
  padding: 15px;
  img {
    width: 186px;
    height: 47px;
    object-fit: cover;

  }
  &__topBar.columns {
    // max-width: $maxContainer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 2px 50px;
    margin-bottom: 0;
    ul {
      margin-bottom: 0;
      li {
        color: white;
        font-size: 1em;
        line-height: 2.166666666666667em;
        text-transform: uppercase;
        font-weight: 600;
        opacity: .5;
        cursor: pointer;
      }
      li:hover {
        opacity: 1;
      }
    }
    img {
      line-height: 0;
    }
    li {
      display: inline-block;
      padding: 0 6px;
      line-height: 0;
      vertical-align: middle;
      &:first-child {
        padding-left: 0;
      }
    }
    .column:last-child {
      text-align: right;
      li {
        padding: 0 14px;
        &:last-child {
          padding-right: 0;
        }
      }
    }

    a {
      color: white;
      font-size: 0.857142857142857em;
      line-height: 2.166666666666667em;
      text-transform: uppercase;
      font-weight: 600;
      opacity: .5;
    }
  }
  @media (max-width: 768px) { 
    display: none;
    padding: 15px 0;
    ul {
      display: none;
    }
    .columns {
      padding: 0 20px;
    }
  }
}
